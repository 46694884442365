// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { addToUserList, removeFromUserList,
         toggleShowSide1, 
         setSpeakerState, setCardSide } from './actions';

import { StarIcon, SpeakerXMarkIcon,EyeSlashIcon, DocumentCheckIcon,
        ArrowTopRightOnSquareIcon, XMarkIcon, TrashIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarFillIcon, SpeakerWaveIcon, EyeIcon, PlayIcon} from '@heroicons/react/24/solid'; 

export default function Card({ card }: any, isPlaying: boolean) {

  const dispatch = useDispatch();
  const [isAdded, setIsAdded] = useState(false);
  const [voicesLoaded, setVoicesLoaded] = useState(false);
  const [allVoices, setAllVoices] = useState<SpeechSynthesisVoice[]>([]);  // Explicitly type as SpeechSynthesisVoice[]

  const userList = useSelector((state: any) => state.app.userList);
  const speaker = useSelector((state: any) => state.app.speakerState) ?? true;
  let side = useSelector((state: any) => state.app.cardSide) ?? false;
  let showSide1 = useSelector((state: any) => state.app.cardsVisibility) ?? true;
  const writeState = useSelector((state: any) => state.app.writeState);

  const prevSide1 = useRef(card.fields.side1);
  const currentAudioRef = useRef<HTMLAudioElement | null>(null);
  const audioCurrentTimeRef = useRef<number>(0);  // Track the current time using useRef

  const [isCanvasVisible, setIsCanvasVisible] = useState(writeState);  // Track canvas visibility
  const [canvasContent, setCanvasContent] = useState<CanvasPath[] | null>(null);  // Allow string or null
  const canvasRef = useRef<any>(null);  // Ref to interact with the canvas

//------------------------------------------------------------
//
//          Triggers
//
//------------------------------------------------------------  

  useEffect(() => {
    if (canvasContent && canvasRef.current && !side) {
      // Restore the canvas content (if any) when the component re-renders
      canvasRef.current.loadPaths(canvasContent);
    }
  }, [side]);


  useEffect(() => {
    if (prevSide1.current !== card.fields.side1) {
      // Trigger the transition when side1 changes
      prevSide1.current = card.fields.side1;
    }
    setIsCanvasVisible(writeState); 
    if (writeState && !speaker) {
      dispatch(setSpeakerState());    
    }
  }, [card.fields.side1]);


  useEffect(() => {
    // Effect cleanup: If the component unmounts, stop any playing audio
    return () => {
      if (currentAudioRef.current) {
        currentAudioRef.current.pause();
        currentAudioRef.current.currentTime = 0;
      }
    };
  }, []);  // Empty dependency array to ensure this only runs on mount/unmount


  useEffect(() => {
    // Check if the card is in the userList and update isAdded accordingly
    const cardIsAdded = userList.some((userCard: any) => userCard.fields.side1 === card.fields.side1);
    setIsAdded(cardIsAdded);
  }, [userList, card]);


  const side1Length = card.fields.side1.length;
  const side2Length = card.fields.side2.length;
  // const maxLength = Math.max(side1Length, side2Length);


  const getVoices = (): Promise<SpeechSynthesisVoice[]> => {
    return new Promise((resolve) => {
      let voices = speechSynthesis.getVoices();
      if (voices.length) {
        resolve(voices)
        return
      }
      speechSynthesis.onvoiceschanged = () => {
        voices = speechSynthesis.getVoices()
        resolve(voices)
      }
    })
  }


useEffect(() => {
    const loadVoices = async () => {
      const loadedVoices: SpeechSynthesisVoice[] = await getVoices();
      setAllVoices(loadedVoices);
      // console.log(loadedVoices);
      setVoicesLoaded(true);
    };
    loadVoices();
  }, []);

//------------------------------------------------------------
//
//          Main speech/audio function
//
//------------------------------------------------------------  

  const speakTTS = async () => {

    speechSynthesis.cancel();

    // if (currentAudioRef.current) {
    //   currentAudioRef.current.pause();
    //   currentAudioRef.current.currentTime = 0;  // Reset the playback position to the beginning
    // }
    if (currentAudioRef.current) {
      // console.log("after checking current: ",audioCurrentTimeRef);
      return;
    }

    if (!allVoices || allVoices.length === 0) {
      console.error("No voices available!");
      return; // Exit if no voices are available
    }

    let side1phrase = card.fields.side1p ? card.fields.side1p : card.fields.side1;
    let side2phrase = card.fields.side2p ? card.fields.side2p : card.fields.side2b;
        
    const cardType = card.type ? card.type : "字";
    const cardlang = card.lang ? card.lang : "zh-TW";

    if ( cardType == "部首" ) {
      side2phrase = side2phrase + "字部";
    } else if ( cardType == "vocab" ) {
      side2phrase = side1phrase;
      side1phrase = card.fields.side2b;
    } else {
      side2phrase = side2phrase + "的" + side1phrase;
    }

    // if ( cardType == "天干" ) {
    //   side2phrase = side1phrase;
    // }
    // console.log("cardtype =",cardType);

    if (cardlang.endsWith(".mp3")) {
    const mp3name = !side ? cardlang.split(".")[0] : `${cardlang.split(".")[0]}b`;
    import(`./assets/mp3/${mp3name}.mp3`)
      .then((audioModule) => {
        // Now, audioModule will contain the dynamically imported module
        let audio = new Audio(audioModule.default);
        // console.log("new audio ref created");
        currentAudioRef.current = audio;
        audio.play();
      })
      .catch((error) => {
        console.error("Error loading audio file:", error);
      });
    }
    else {
      const message = new SpeechSynthesisUtterance();

      const voiceMap = new Map<string, string[]>([
        ['zh-TW', ['Meijia', 'Mei-Jia']], // Both Meijia and Mei-Jia are possible
        ['zh-CN', ['Tingting']],
        ['de-DE', ['Anna']],
        ['es-ES', ['Mónica']],
        ['ja-JP', ['Kyoko']],
        ['en-US', ['Samantha']],  // Default voice
      ]);
      const selectedVoiceNames = voiceMap.get(cardlang) || ['Samantha'];  // Default to 'Samantha' if not found
      let voice = null;

      for (const voiceName of selectedVoiceNames) {
        voice = allVoices.find(v => v.name === voiceName);
        if (voice) {
          break;  // Stop once we find the matching voice
        }
      }

      if (voice) {
        message.voice = voice;
      } else {
        console.warn(`Voice for ${cardlang} not found, using default voice`);
      }

      message.text = !side ? side2phrase : side1phrase ;
      message.volume = 1; // Volume range = 0 - 1
      message.rate = 0.8; // Speed of the text read , default 1
      message.lang = cardlang; // Language, default 'zh-TW'
      speechSynthesis.speak(message);
    }
  }

  useEffect(() => {
    if (voicesLoaded && speaker) {
      speakTTS();
    }
  }, [voicesLoaded, speaker, side, card.fields.side1]);


//------------------------------------------------------------
//
//          Handlers
//
//------------------------------------------------------------  

  function handleToggleVisibility(event: any) {
    event.stopPropagation(); // Prevent card from being flipped
    dispatch(toggleShowSide1()); // Dispatch action to toggle visibility
  }

  function handleClick() {
    if (!isCanvasVisible) {
      setIsCanvasVisible(writeState); 
      dispatch(setCardSide(!side));
    }
  }

  function handleSpeakerClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    dispatch(setSpeakerState());    

    // console.log("currentAudioRef.current",currentAudioRef.current);
    if (currentAudioRef.current) {
      // console.log("speaker",speaker);
      if (speaker ) {
        audioCurrentTimeRef.current = currentAudioRef.current.currentTime;
        // console.log("Pausing at ",audioCurrentTimeRef.current);
        currentAudioRef.current.pause();        
      } else {
        currentAudioRef.current.currentTime = audioCurrentTimeRef.current;
        // console.log("resuming at ",audioCurrentTimeRef.current);
        currentAudioRef.current.play();        
      }
    }
  }

  function handleReplayClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    if (speaker) {
      speakTTS();
    }
  }


  function handleAddRemoveClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    if (isAdded) {
      dispatch(removeFromUserList(card));
    } else {
      dispatch(addToUserList(card));
    }
    setIsAdded(!isAdded);
  }

  // Toggle canvas visibility
  const handleToggleCanvas = (event: React.MouseEvent) => {
    event.stopPropagation();  // Prevent card flip
    setIsCanvasVisible(!isCanvasVisible);  // Toggle canvas visibility
  };

    // Clear the canvas
  const handleClearCanvas = () => {
    if (canvasRef.current) {
      canvasRef.current.clearCanvas();  // Clear the drawing
      setCanvasContent(null);  // Clear the stored content as well
    }
  };

const handleSaveCanvas = () => {
  if (canvasRef.current) {
    canvasRef.current.exportPaths().then((dataPaths: CanvasPath[]) => {

      setCanvasContent(dataPaths);

      // Log the exported paths (this is the raw data)
      // console.log('Exported Canvas Paths:', dataPaths);

      const side1 = card?.fields?.side1; // Make sure card exists and contains `side1`

      // Create an object that contains both dataPaths and side1 with a new key "character"
      const dataToSend = {
        word: side1, // Renaming side1 to word
        paths: dataPaths
      };

      // Convert the canvas paths to JSON format
      const jsonData = JSON.stringify(dataToSend);

      // Optionally, send the data via an API call
      sendCanvasDataToAPI(jsonData);

      // Optionally, trigger a download by creating a link (if you want to allow users to download)
      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(new Blob([jsonData], { type: 'application/json' }));
      // link.download = 'canvas-data.json';
      // link.click();

      // Close the canvas after saving the image
      setIsCanvasVisible(false);
      dispatch(setCardSide(!side));
    });
  }
};


const sendCanvasDataToAPI = async (jsonData: string) => {
  try {
    const response = await fetch('http://192.168.1.102:5000/flashcard', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonData,  // Send the JSON data as the request body
    });

    if (!response.ok) {
      throw new Error('Failed to send data to the API');
    }

    // Parse the JSON response (which is expected to be a boolean value)
    const result = await response.json();

    if (result) {
      // console.log('Response message:', result.message);  // This is the success message from the backend
      // console.log('Word:', result.match);  // The word (side1) sent from the frontend

      // Handle the response (true or false)
    } else {
      console.error('Unexpected response from the API:', result);
    }

  } catch (error) {
    console.error('Error sending canvas data to API:', error);
  }
};


//------------------------------------------------------------
//
//          Helper functions
//
//------------------------------------------------------------  

  const getFontSize = () => {
    if (side1Length > 3) {
      return "small-font";
    } else if (side1Length > 2) {
      return "medium-font";
    } else {
      return "large-font";
    }
  };


  // Font class based on cardType

  const getFontFamilyFrontSide = () => {
    const cardType = card.type || "字"; // Default to "字" if cardType is undefined
    switch (cardType) {
      case "vocab":
        return "serif";
      default:
        return "frontfont";
    }
  };


  const getFontFamilyBackSide = () => {
    const cardType = card.type || "字"; // Default to "字" if cardType is undefined

    switch (cardType) {
      case "注音":
        return "frontfont";
      case "vocab":
        return "serif";
      default:
        return "backfont";
    }
  };
  
//------------------------------------------------------------
//
//          Rendering
//
//------------------------------------------------------------  


  return (
    <div className={`card ${side ? "side" : ""}`} onClick={handleClick}>
      <div>

        {!isCanvasVisible &&
          <div className="card-top">
            <button onClick={handleAddRemoveClick} className="add-remove-button">
              {!isAdded ? <StarIcon style={{ width: '36px', height: '36px', color: 'white' }} /> : <StarFillIcon style={{ width: '36px', height: '36px', color: 'yellow' }} />}
            </button>      
            <button onClick={handleSpeakerClick} className="pronounce-button">
              {speaker ? <SpeakerWaveIcon style={{ width: '36px', height: '36px', color: 'white' }} /> : <SpeakerXMarkIcon style={{ width: '36px', height: '36px', color: 'white' }} />}
            </button>
            <button onClick={handleToggleVisibility} className="toggleside1-button" style={{ visibility: writeState ? 'hidden' : 'visible' }}>
              {showSide1 ? <EyeIcon style={{ width: '36px', height: '36px', color: 'white' }} /> : <EyeSlashIcon style={{ width: '36px', height: '36px', color: 'white' }} />}
            </button>
          </div>
        }

  
        {isCanvasVisible &&
          <div className="card-top">
            {/* Left Button: Toggle Canvas Visibility */}
            <button onClick={handleClearCanvas} className="canvas-control-button">
                <TrashIcon style={{ width: '36px', height: '36px', color: 'white' }} />
            </button>

            {/* Middle Button: Clear Canvas */}
            <button onClick={handleReplayClick} className="pronounce-button">
              {speaker ? <SpeakerWaveIcon style={{ width: '36px', height: '36px', color: 'white' }} /> : <SpeakerXMarkIcon style={{ width: '36px', height: '36px', color: 'white' }} />}
            </button>

            {/* Right Button: Save Canvas as PNG and Close */}
            <button onClick={handleSaveCanvas} className="canvas-control-button">
                <DocumentCheckIcon style={{ width: '36px', height: '36px', color: 'white' }} />
            </button>
          </div>
        }

      </div>

      {/* Conditionally render the React Sketch Canvas */}
      {isCanvasVisible && (
        <div className="canvas-container">
          <ReactSketchCanvas
            ref={canvasRef}
            width="100%"
            height="300px"
            strokeColor="black"  // Drawing color
            strokeWidth={6}      // Width of the strokes
            canvasColor="white"  // Canvas background color
          />
        </div>
      )}


      {/* Card Front side */}
      {!isCanvasVisible && !showSide1 && !side && 
        <div>
          <button onClick={handleReplayClick} className="replay-button">
            <div className={`eye-icon-transition`}>
              <EyeSlashIcon style={{ width: '60px', height: '60px', color: 'white' }} />
            </div>
          </button>
        </div>
      }
      
      {!isCanvasVisible && showSide1 &&  
        <div className={`front ${getFontSize()}`} style={{ fontFamily: getFontFamilyFrontSide()}}>{card.fields.side1}
        </div>
      }

      {/* Card Back side */}
      {!isCanvasVisible && 
        <div className={`back ${getFontSize()} ${getFontFamilyBackSide()}`}>
          <div>{card.fields.side2}</div>
          { (card.type != "vocab") && <div className="fontFamily: doublefont">{card.fields.side2}</div> }
          <div className="bottomalign" style={{ fontFamily: getFontFamilyBackSide() }}><hr></hr>{card.fields.side2b}</div>
        </div>
      }
    </div>
  );
}

