// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import * as React from "react";
import { render } from "react-dom";

import App from "./App";

const rootElement = document.getElementById("root");
render(<App />, rootElement);
