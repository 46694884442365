// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';


const appPersistConfig = {
  key: 'app',
  storage: storage,
  blacklist: ['cardSide'],
}

const flashcardPersistConfig = {
  key: 'flashcard',
  storage: sessionStorage,
  stateReconciler: hardSet,
};



interface AppState {
  scrollPosition: number;
  randomState: boolean;
  writeState: boolean;
  currentSet: any;
  userList: any;
  currentSubMenu: any;
  speakerState: boolean;
  cardSide: boolean;
  cardsVisibility: boolean;
}

const initialState: AppState = {
  scrollPosition: 0,
  randomState: false,
  writeState: false,
  currentSet: [],
  userList: [],
  currentSubMenu: null,
  speakerState: true,
  cardSide: false,
  cardsVisibility: true,
};


function appReducer(state = initialState, action: any) {
  switch (action.type) {
    case 'UPDATE_SCROLL_POSITION':
      return { ...state, scrollPosition: action.payload };
    case 'TOGGLE_RANDOM_STATE':
      return { ...state, randomState: !state.randomState };
    case 'TOGGLE_WRITE_STATE':
      return { ...state, writeState: !state.writeState };
    case 'SET_CURRENT_SET':
      return { ...state, currentSet: action.payload };
    case 'ADD_TO_USER_LIST':
      return { ...state, userList: [...state.userList, action.payload] };
    case 'REMOVE_FROM_USER_LIST':
      return { ...state, userList: state.userList.filter((card: any) => card.fields.side1 !== action.payload.fields.side1) };
    case "SET_CURRENT_SUBMENU":
      return { ...state, currentSubMenu: action.payload };
    case "SET_SPEAKER_STATE":
      return { ...state, speakerState: !state.speakerState };
    case "SET_CARD_SIDE":
      return { ...state, cardSide: action.payload };
    case 'TOGGLE_SHOW_SIDE1':
      return { ...state, cardsVisibility: !state.cardsVisibility };
    default:
      return state;
  }
}

const initialFlashcardState: any = {
    flashcarddata: 0,
}

function flashcardReducer(state = initialFlashcardState, action: any) {
  switch (action.type) {
    case 'RECEIVE_FLASHCARDDATA':
      return { ...state, flashcarddata: action.payload };
    case 'PURGE_FLASHCARDDATA':
      return {...state, flashcarddata: null };
    default:
      return state;
  }
}



const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  flashcard: persistReducer(flashcardPersistConfig, flashcardReducer),
})

const persistedReducer = rootReducer;

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>

export { store, persistor };